body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin: 0;
  //padding-bottom: 20px;
}

html {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}

dl {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -15px;
  width: 100%;
}

dd,
dt {
  padding: 0 0 20px;
}

dd {
  margin: 0;
  width: 70%;
}

dt {
  font-weight: bold;
  width: 30%;
}

.nowrap {
  white-space: nowrap;
}

A {
  color: #2196f3;
}

A:hover {
  color: #42a5f5;
}

H1 {
  font-weight: 300;
  font-size: 32px;
}
